import { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="master_svg0_1635_67231">
          <rect x="0" y="0" width="18" height="18" rx="0" />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_1635_67231)">
        <g>
          <path
            d="M13.3125,0.750000469387C14.8658,0.750000268221,16.125,2.0092,16.125,3.5625L16.125,14.4375C16.125,15.9908,14.8658,17.25,13.3125,17.25L4.6875,17.25C3.1342,17.25,1.87499986589,15.9908,1.875,14.4375L1.875,3.5625C1.875000536442,2.0092,3.1342,0.750000402331,4.6875,0.750000469387L13.3125,0.750000469387ZM13.3125,1.875L10.3125,1.875L10.3125,7.15763C10.31258,7.65157,9.92938,8.06085,9.436499999999999,8.093250000000001L9.375,8.095130000000001C9.126529999999999,8.09517,8.888200000000001,7.99658,8.71238,7.821L7.49813,6.60787L6.28837,7.81913C5.94085,8.167169999999999,5.383430000000001,8.187470000000001,5.0115,7.86562L4.96237,7.81987C4.78639,7.64411,4.6875,7.40545,4.6875,7.1565L4.6875,1.875C3.7555199999999997,1.875,3,2.6305199999999997,3,3.5625L3,14.4375C3,15.3695,3.7555199999999997,16.125,4.6875,16.125L13.3125,16.125C14.2445,16.125,15,15.3695,15,14.4375L15,3.5625C15,2.6305199999999997,14.2445,1.875,13.3125,1.875ZM9.1875,1.875L5.8125,1.875L5.8125,6.7035L6.86062,5.65613L6.92287,5.6025C7.29601,5.31263,7.82655,5.34574,8.16075,5.67975L9.1875,6.705L9.1875,1.875Z"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
