import { Button, Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface CustomModalProps {
  visible: boolean
  onClose: () => void
}

const CustomModal: React.FC<CustomModalProps> = ({ visible, onClose }) => {
  const navigate = useNavigate()
  const goPage = (path: string) => {
    onClose()
    navigate(path)
  }
  return (
    <Modal title="未通过实名认证" open={visible} onCancel={onClose} footer={null}>
      <div className="my-[9px] h-[1px] w-full bg-[#eeeeee]"></div>
      <p className=" text-[14px] leading-[22px]">
        系统检测到您尚未通过实名认证，只有通过实名认证的用户可以进行后续操作，请先通过实名认证
      </p>
      <div className=" mt-[30px] flex justify-end">
        <Button className="gray-button mr-[10px] px-[16px] text-[14px]" onClick={onClose}>
          关闭
        </Button>
        <Button className="black-button px-[16px] text-[14px]" onClick={() => goPage('/setting?value=3')}>
          去认证
        </Button>
      </div>
    </Modal>
  )
}

export default CustomModal
