import { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="100%" height="100%" viewBox="0 0 18 18">
      <g>
        <g>
          <path
            d="M16.710294921875,9.16483984375L9.383664921875,1.83820784375C9.170854921875,1.62540184375,8.829234921874999,1.62540184375,8.616424921875,1.83820784375L1.289794921875,9.16483984375L2.085289921875,9.96032984375L3.375044921875,8.67057984375L3.375044921875,15.23448984375C3.375044921875,15.83638984375,3.850204921875,16.31258984375,4.452104921875,16.31258984375L13.547994921875,16.31258984375C14.149894921875,16.31258984375,14.624994921875,15.83638984375,14.624994921875,15.23448984375L14.624994921875,8.67057984375L15.914794921875,9.96032984375L16.710294921875,9.16483984375ZM4.500044921875,7.54557984375L9.000044921875,3.0455798437499997L13.499994921875,7.54557984375L13.499994921875,15.18758984375L4.500044921875,15.18758984375L4.500044921875,7.54557984375ZM6.750044921875,13.50008984375L6.750044921875,12.37508984375L11.250044921875,12.37508984375L11.250044921875,13.50008984375L6.750044921875,13.50008984375Z"
            fill-rule="evenodd"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
