import { Env } from '@/type/common'
import { Logger } from '@kulee/helper'

// 环境
export const ENV = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  PRODUCTION: 'production'
}
// PC和移动端边界宽度
export const BOUNDARY_WHDTH = 820
// 日志前缀
export const LOGGER_PREFIX = 'PAGE'
// 日志输出等级
export const LOGGER_LEVEL = Logger.LEVEL.INFO

export const AVATAR = 'https://painter.cn-bj.ufileos.com/avatar.png'
export const ACTIVITY = 'https://painter.cn-bj.ufileos.com/default_cover.png'
export const DESCRIPTION = '这人很懒，啥都没留下。。。'

export const COLUMNS = ['title', 'likeCount', 'userId', 'createTime', 'updateTime']
export const TEACHERCOLUMNS = ['userId', 'nickName', 'createTime', 'updateTime']

export const ISASC = ['asc', 'desc']

export const THEME = {
  token: {
    colorPrimary: '#282828'
  }
}

/**
 * 获取API地址
 * @param env 环境
 * @returns API地址
 */
export const getApiBaseHost = (env: Env): string => {
  switch (env) {
    case ENV.DEVELOPMENT:
      return 'http://192.168.50.25:8081'
    case ENV.TEST:
      // return 'https://www.magneticteacher.com/api'
      return 'https://tgadev.just4fun.asia/art'
      return 'http://192.168.50.2:8081'
      // return 'http://192.168.50.30:8081'
      return 'http://192.168.50.2:8081'
    case ENV.PRODUCTION:
      return 'https://www.magneticteacher.com/api'
  }

  return 'https://tgadev.just4fun.asia/art'
}
export const getUploadBaseHost = (env: Env): string => {
  switch (env) {
    case ENV.DEVELOPMENT:
      return 'http://192.168.50.25:8081'
    case ENV.TEST:
      return 'https://tgadev.just4fun.asia/art'
      return 'http://192.168.50.2:8081'
    case ENV.PRODUCTION:
      return 'https://gaitu.just4fun.asia/api'
  }

  return 'https://tgadev.just4fun.asia/art'
}

/**
 * 获取WEB地址
 * @param env 环境
 * @returns WEB地址
 */
export const getBaseHost = (env: Env): string => {
  switch (env) {
    case ENV.DEVELOPMENT:
      return 'http://192.168.50.25:8081'
    case ENV.TEST:
      return 'http://192.168.50.25:8081'
    case ENV.PRODUCTION:
      return 'https:www.magneticteacher.com'
  }

  return 'https://www.magneticteacher.com'
}

/**
 * 获取视频直播配置
 * @param env
 */
export const getWebRtcConfig = (env: Env): { appId: number; appKey: string } => {
  switch (env) {
    case ENV.DEVELOPMENT:
      return {
        appId: 6976,
        appKey: 'YOUME20859B1EE41178CCEA2B6528073C74F16CE6652C'
      }
    case ENV.TEST:
      return {
        appId: 8557,
        appKey: 'YOUME4C459985BF4E1D6A42595C12435225D424740514'
      }
    case ENV.PRODUCTION:
      // return {
      //   appId: 6976,
      //   appKey: 'YOUME20859B1EE41178CCEA2B6528073C74F16CE6652C'
      // }
      return {
        appId: 8557,
        appKey: 'YOUME4C459985BF4E1D6A42595C12435225D424740514'
      }
  }

  return {
    appId: 6976,
    appKey: 'YOUME20859B1EE41178CCEA2B6528073C74F16CE6652C'
  }
}

export const getIMConfig = (env: Env): { appKey: string } => {
  switch (env) {
    case ENV.DEVELOPMENT:
      return {
        appKey: 'YOUME20859B1EE41178CCEA2B6528073C74F16CE6652C'
      }
    case ENV.TEST:
      return {
        appKey: 'YOUME1D05F356840F976384B2CE1C03D9D6D1E04506F2'
      }
    case ENV.PRODUCTION:
      return {
        // appKey: 'YOUME20859B1EE41178CCEA2B6528073C74F16CE6652C'
        appKey: 'YOUME1D05F356840F976384B2CE1C03D9D6D1E04506F2'
      }
  }

  return {
    appKey: 'YOUME20859B1EE41178CCEA2B6528073C74F16CE6652C'
  }
}
