import { getInfo } from '@/service/UCService'
import { UserInfo } from '@/type/uc'

// 判断登录
export const isLogin = async (): Promise<boolean> => {
  // TODO：从本地获取登录信息判断
  if (localStorage.getItem('Authorization')) {
    return true
  }
  return false
}

// 登录
export const login = async () => {
  return true
}

// 获取用户信息
export const getUserInfo = async (): Promise<UserInfo> => {
  const user = await getInfo()
  return user
}
