import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="100%" height="100%" viewBox="0 0 18 18">
      <g>
        <g>
          <path
            d="M15.82007578125,5.20313L9.00012578125,1.40625L2.18017578125,5.20313L2.18017578125,12.79685L9.00012578125,16.59375L15.82007578125,12.79685L15.82007578125,5.20313ZM3.30517578125,12.13565L3.30517578125,5.8644L9.00012578125,2.6938500000000003L14.69507578125,5.8644L14.69507578125,12.13565L9.00012578125,15.30615L3.30517578125,12.13565ZM9.00012578125,12.37505C10.86408578125,12.37505,12.37517578125,10.86396,12.37517578125,9C12.37517578125,7.13604,10.86408578125,5.625,9.00012578125,5.625C7.13616578125,5.625,5.62512578125,7.13604,5.62512578125,9C5.62512578125,10.86396,7.13616578125,12.37505,9.00012578125,12.37505ZM9.00012578125,6.75C10.24276578125,6.75,11.25012578125,7.75736,11.25012578125,9C11.25012578125,10.24264,10.24276578125,11.25,9.00012578125,11.25C7.75748578125,11.25,6.75012578125,10.24264,6.75012578125,9C6.75012578125,7.75736,7.75748578125,6.75,9.00012578125,6.75Z"
            fill-rule="evenodd"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
