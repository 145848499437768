import api from '@/config/api'
import { PageParams, Result } from '@/type/common'
import {
  GroupInstructionItem,
  ResultGroupInstructionDetail,
  ResultGroupInstructionList,
  ResultGroupInstructionOrderList,
  ResultQualificationCheck
} from '@/type/groupInstruction'
import { baseHost } from './UCService'

// 校验  /group/instruction/qualification/check
export async function checkQualification(): Promise<ResultQualificationCheck> {
  try {
    const res: ResultQualificationCheck = await api.get(`${baseHost}/group/instruction/qualification/check`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 提交审核 /group/instruction/qualification
export async function submitQualification(): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/qualification`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//取消审核 /group/instruction/config/cancel/{id}
export async function cancelQualification(id: string): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/config/cancel/${id}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 删除团课配置 /group/instruction/config/{ids}
export async function deleteGroupInstructionConfig(ids: string): Promise<Result> {
  try {
    const res: Result = await api.delete(`${baseHost}/group/instruction/config/${ids}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//group/instruction/config/list 查询团课配置列表
export async function groupInstructionConfigList(params: PageParams): Promise<ResultGroupInstructionList> {
  try {
    const { auditStatus, pageSize, pageNum, name, courseStatus, startTime, endTime, orderByColumn, isAsc } = params
    let url = `${baseHost}/group/instruction/config/list?`
    if (pageSize) url += `&pageSize=${pageSize}`
    if (pageNum) url += `&pageNum=${pageNum}`
    if (auditStatus != null) url += `&auditStatus=${auditStatus}`
    if (courseStatus != null) url += `&courseStatus=${courseStatus}`
    if (name) url += `&name=${name}`
    if (startTime) url += `&startTime=${startTime}`
    if (endTime) url += `&endTime=${endTime}`
    if (orderByColumn) url += `&orderByColumn=${orderByColumn}`
    if (isAsc) url += `&isAsc=${isAsc}`

    const res: ResultGroupInstructionList = await api.get(url)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 新增团课配置 /group/instruction/config
export async function addGroupInstructionConfig(data: GroupInstructionItem): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/config`, data)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 修改团课配置 /group/instruction/config
export async function updateGroupInstructionConfig(data: GroupInstructionItem): Promise<Result> {
  try {
    const res: Result = await api.put(`${baseHost}/group/instruction/config`, data)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//获取团课配置详细信息  /group/instruction/config/{id}
export async function getGroupInstructionConfigDetail(id: string): Promise<ResultGroupInstructionDetail> {
  try {
    const res: ResultGroupInstructionDetail = await api.get(`${baseHost}/group/instruction/config/${id}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 推迟授课  /group/instruction/config/delay/{id}  post
export async function delayGroupInstructionConfig(id: string): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/config/delay/${id}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 结算申请  /group/instruction/config/settle/apply post
export async function applySettleGroupInstructionConfig(data: { id: string; settleUrl: string }): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/config/settle/apply`, data)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 精品团课列表 /group/instruction/config/portal get
export async function portalGroupInstructionConfig(params: PageParams): Promise<ResultGroupInstructionList> {
  try {
    const { auditStatus, pageSize, pageNum, name, courseStatus } = params
    let url = `${baseHost}/group/instruction/config/portal?`
    if (pageSize) url += `&pageSize=${pageSize}`
    if (pageNum) url += `&pageNum=${pageNum}`
    if (auditStatus) url += `&auditStatus=${auditStatus}`
    if (courseStatus) url += `&courseStatus=${courseStatus}`
    if (name) url += `&name=${name}`

    const res: ResultGroupInstructionList = await api.get(url)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 查询学生课程信息 /group/instruction/order/students 列表接口
export async function groupInstructionOrderStudents(params: PageParams): Promise<ResultGroupInstructionOrderList> {
  try {
    const { pageSize, pageNum, courseStatus, orderByColumn, isAsc, startTime, endTime } = params
    let url = `${baseHost}/group/instruction/order/students?`
    if (pageSize) url += `&pageSize=${pageSize}`
    if (pageNum) url += `&pageNum=${pageNum}`
    if (orderByColumn) url += `&orderByColumn=${orderByColumn}`
    if (isAsc) url += `&isAsc=${isAsc}`
    if (startTime) url += `&startTime=${startTime}`
    if (endTime) url += `&endTime=${endTime}`
    url += `&courseStatus=${courseStatus}`

    const res: ResultGroupInstructionOrderList = await api.get(url)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 用户退款  /group/instruction/order/refund/{orderNo} post
export async function refundGroupInstructionOrder(orderNo: string): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/order/refund/${orderNo}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 学生进入教室  /group/instruction/order/enter/{groupId} post
export async function enterGroupInstructionOrder(groupId: string): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/order/enter/${groupId}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//开始录制 /group/instruction/config/start/record/{id}
export async function startRecordGroupInstructionConfig(id: string): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/group/instruction/config/start/record/${id}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}
