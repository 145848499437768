import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="100%" height="100%" viewBox="0 0 18 18">
      <g>
        <g>
          <path
            d="M14.91015,8.99852C14.91015,5.707800000000001,12.25855,3.05025,9.00018,3.05025C6.84778,3.05025,4.96019,4.2098700000000004,3.92676,5.945600000000001L6.00385,5.945600000000001L6.00385,7.03005L2.957087,7.03006C2.4220610000000002,7.03006,1.96875,6.61097,1.96875,6.07595L1.96875,2.97283L3.0472900000000003,2.97283L3.0472900000000003,5.31001C4.27879,3.3049299999999997,6.48429,1.96875,9.00018,1.96875C12.86145,1.96875,15.99165,5.11609,15.99165,8.99852L14.91015,8.99852ZM3.09023,8.99846L2.0087305,8.99846C2.0087284,12.88085,5.13891,16.02825,9.00017,16.02825C11.51582,16.02825,13.72115,14.69235,14.95275,12.68755L14.95275,15.02415L16.03125,15.02415L16.03125,11.92109C16.03125,11.38606,15.57795,10.96698,15.04295,10.96698L11.99615,10.96698L11.99615,12.05145L14.07355,12.05145C13.04015,13.78715,11.15257,14.94675,9.00017,14.94675C5.74177,14.94675,3.09023,12.28915,3.09023,8.99846Z"
            fill-rule="evenodd"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
