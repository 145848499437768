import React, { FC } from 'react'
import { useRoutes } from 'react-router-dom'
import routes from './routes'

const Router: FC = () => {
  const element = useRoutes(routes)

  return <>{element}</>
}

export default Router
