import React from 'react'
import './App.css'
import Router from './router'
import { Auth } from './component/Auth'

function App() {
  return (
    <Auth>
      <Router />
    </Auth>
  )
}

export default App
