import React, { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../../page/Mobile/component/Header'
import { BOUNDARY_WHDTH } from '@/config/constants'
import { useWindowSize } from 'react-use'

const MobileLayout: React.FC = () => {
  const location = useLocation()
  const { width } = useWindowSize()
  const navigate = useNavigate()

  useEffect(() => {
    if (width >= BOUNDARY_WHDTH) {
      navigate('/')
    }
  }, [width])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className=" min-h-screen bg-[#f7f7f7]">
      <Header />
      <Outlet />
    </div>
  )
}

export default MobileLayout
