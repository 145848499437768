import api from '@/config/api'
import { baseHost } from './UCService'
import { PageParams, Result } from '@/type/common'
import {
  BindWithdraw,
  CreateBindWithdraw,
  ResultBalance,
  ResultBindWithdraw,
  ResultRecharge,
  ResultTradeList,
  WithdrawOrderType,
  rechargeType
} from '@/type/withdraw'

//创建绑定收款账号 /account/bind/withdraw
export async function bindWithdraw(body: CreateBindWithdraw) {
  try {
    const res: Result = await api.post(`${baseHost}/account/bind/withdraw`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 修改绑定收款账号 /account/bind/withdraw
export async function bindWithdrawUpdate(body: BindWithdraw) {
  try {
    const res: Result = await api.put(`${baseHost}/account/bind/withdraw`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 获取绑定收款账号 /account/bind/withdraw
export async function bindWithdrawList(): Promise<ResultBindWithdraw> {
  try {
    const res: ResultBindWithdraw = await api.get(`${baseHost}/account/bind/withdraw`)
    return res
  } catch (error) {
    throw error
  }
}

//获取当前账号钱包余额 /account/balance/current
export async function balanceCurrent(): Promise<ResultBalance> {
  try {
    const res: ResultBalance = await api.get(`${baseHost}/account/balance/current`)
    return res
  } catch (error) {
    throw error
  }
}

// 获取当前用户交易记录 /trade/history/current
export async function tradeHistoryCurrent(params: PageParams): Promise<ResultTradeList> {
  try {
    const { pageNum, pageSize, orderByColumn, isAsc, tradeType, startTime, endTime } = params
    let url = `${baseHost}/trade/history/current?`
    if (pageNum) url += `&pageNum=${pageNum}`
    if (pageSize) url += `&pageSize=${pageSize}`
    if (orderByColumn) url += `&orderByColumn=${orderByColumn}`
    if (isAsc) url += `&isAsc=${isAsc}`
    if (tradeType) url += `&tradeType=${tradeType}`
    if (startTime) url += `&startTime=${startTime}`
    if (endTime) url += `&endTime=${endTime}`
    const res: ResultTradeList = await api.get(url)
    return res
  } catch (error) {
    throw error
  }
}

// 账号充值下单 /account/recharge
export async function accountRecharge(body: rechargeType): Promise<ResultRecharge> {
  try {
    const res: ResultRecharge = await api.post(`${baseHost}/account/recharge`, {
      ...body
    })
    return res
  } catch (error) {
    throw error
  }
}

//用户新增提现申请 /payment/withdraw/order
export async function withdrawOrder(body: WithdrawOrderType) {
  try {
    const res: Result = await api.post(`${baseHost}/payment/withdraw/order`, {
      ...body
    })
    return res
  } catch (error) {
    throw error
  }
}

// 查询提现次数/payment/withdraw/order/count
export async function withdrawOrderCount(): Promise<any> {
  try {
    const res: any = await api.get(`${baseHost}/payment/withdraw/order/count`)
    return res
  } catch (error) {
    throw error
  }
}