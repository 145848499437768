import { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
    >
      <defs>
        <clipPath id="master_svg0_5289_56436">
          <rect x="0" y="0" width="20" height="20" rx="0" />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_5289_56436)">
        <g>
          <path
            d="M13.59333,1.890625L5.4399999999999995,1.890625C4.51,1.890625,3.75,2.623958,3.75,3.523955L3.75,17.257325C3.75,17.457325,3.8400002,17.643925,3.993333,17.770625000000003C4.113334,17.870625,4.263333,17.923925,4.416667,17.923925C4.46,17.923925,4.5,17.920625,4.543333,17.910625L13.9233,16.093925C14.2838,16.023425,14.5193,15.674625,14.45,15.313925C14.38,14.953925,14.03,14.713925,13.67,14.787325L5.08333,16.450625000000002L5.08333,3.523955C5.08333,3.3572949999999997,5.24333,3.223955,5.4399999999999995,3.223955L13.59333,3.223955C14.9167,3.223955,15.9967,4.227295,15.9967,5.460625L15.9967,17.257325C15.9967,17.623925,16.2967,17.923925,16.6633,17.923925C17.03,17.923925,17.33,17.623925,17.33,17.257325L17.33,5.460625C17.33,3.490625,15.6533,1.890624809265,13.59333,1.890625Z"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
        <g>
          <path
            d="M10.0368065625,6.3700054999999995C9.6986765625,6.1398476,9.2606305625,6.1167252,8.9001435625,6.3100055C8.5401435625,6.5033385,8.3134765625,6.8766715000000005,8.3134765625,7.2833375L8.3134765625,11.596667499999999C8.3134765625,12.006667499999999,8.5368095625,12.3800075,8.9001435625,12.5700075C9.0601435625,12.656667500000001,9.2368105625,12.7000075,9.4134765625,12.7000075C9.6334765625,12.7000075,9.8534765625,12.6333375,10.0501465625,12.5033375L12.8768065625,10.486677499999999C13.224076562499999,10.2513475,13.4324765625,9.8594975,13.433476562500001,9.4400075C13.433476562500001,9.0233375,13.2301465625,8.6366775,12.8868065625,8.400007500000001L10.0368065625,6.3700054999999995ZM9.6468065625,11.150007500000001L9.6468065625,7.7300075L12.0434765625,9.4400075L9.6468065625,11.150007500000001Z"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
