import api from '@/config/api'
import { baseHost } from './UCService'
import { ResultCount, ResultMsgList } from '@/type/msg'
import { PageParams, Result } from '@/type/common'

//获取当前用户未读消息数 /msg/info/user/count
export async function msgInfoUserCount(): Promise<ResultCount> {
  try {
    const res: ResultCount = await api.get(`${baseHost}/msg/info/user/count`)
    return res
  } catch (error) {
    throw error
  }
}

// 查询用户消息阅读记录列表 /msg/info/user/list
export async function msgInfoUserList(params: PageParams): Promise<ResultMsgList> {
  try {
    const { pageNum, pageSize, orderByColumn, isAsc } = params
    let url = `${baseHost}/msg/info/user/list?`
    if (pageNum) url += `&pageNum=${pageNum}`
    if (pageSize) url += `&pageSize=${pageSize}`
    if (orderByColumn) url += `&orderByColumn=${orderByColumn}`
    if (isAsc) url += `&isAsc=${isAsc}`
    const res: ResultMsgList = await api.get(url)
    return res
  } catch (error) {
    throw error
  }
}

//修改用户消息阅读记录 /msg/info/user
export async function msgInfoUser(body: { id: string; isRead: boolean }): Promise<ResultCount> {
  try {
    const res: ResultCount = await api.put(`${baseHost}/msg/info/user`, {
      ...body
    })
    return res
  } catch (error) {
    throw error
  }
}

// 全部已读 /msg/info/user/read/all
export async function msgInfoUserReadAll(): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/msg/info/user/read/all`)
    return res
  } catch (error) {
    throw error
  }
}

// 删除用户消息阅读记录 /msg/info/user/{ids}
export async function msgInfoUserDelete(ids: string): Promise<Result> {
  try {
    const res: Result = await api.delete(`${baseHost}/msg/info/user/${ids}`)
    return res
  } catch (error) {
    throw error
  }
}
