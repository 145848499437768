import TencentCloudChat, { ChatSDK, Conversation, Message } from '@tencentcloud/chat'
import {
  TUIChat,
  TUIChatHeader,
  TUIContact,
  TUIContactInfo,
  TUIConversation,
  TUIConversationList,
  TUIKit,
  TUIManage,
  TUIMessageInput,
  TUIMessageList,
  TUIProfile,
  useConversation
} from '@tencentcloud/chat-uikit-react'
// import '@tencentcloud/chat-uikit-react/dist/cjs/index.css'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TUIChatEmpty from './TUIChatEmpty'
import './index.css'
import './index.scss'

export default function SocialDemo(props: any) {
  const { handleTaskList, className } = props
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = props?.userId || searchParams.get('id')
  const [moduleValue, setModuleValue] = useState('chats')
  const [chat, setChat] = useState<ChatSDK>()
  const [conversation, setConversation] = useState<Conversation>()
  const { createConversation } = useConversation(chat)

  useEffect(() => {
    if (props?.chat) {
      const { chat } = props
      setChat(chat)
    }
  }, [props])

  const getConversation = useCallback(async () => {
    if (id) {
      const res = await createConversation(`C2C${id}`)
      setConversation(res)
    }
  }, [id, createConversation])

  useEffect(() => {
    getConversation()
  }, [getConversation])

  const handleSendMessage = async (message: Message) => {
    handleTaskList('send')
    return chat?.sendMessage(message)
  }

  const handleRevokeMessage = async (message: Message) => {
    handleTaskList('recall')
    return chat?.revokeMessage(message)
  }

  const handleCreateConversation = async (params: string | any) => {
    // create c2c conversation
    if (typeof params === 'string') {
      const { data } = await chat?.getConversationProfile(params)
      handleTaskList('startChat')
      return data.conversation
    }

    // create group conversation
    const {
      name,
      type,
      groupID,
      introduction,
      notification,
      avatar,
      maxMemberNum,
      joinOption,
      memberList = [],
      groupCustomField = []
    } = params
    const res = await chat?.createGroup({
      name,
      type,
      groupID,
      introduction,
      notification,
      avatar,
      maxMemberNum,
      joinOption,
      memberList,
      groupCustomField
    })
    const { groupID: createdGroupID } = res.data.group
    if (type === TencentCloudChat.TYPES.GRP_AVCHATROOM) {
      await chat?.joinGroup({
        groupID: createdGroupID,
        type: TencentCloudChat.TYPES.GRP_AVCHATROOM
      })
    }
    const { data } = await chat?.getConversationProfile(`GROUP${createdGroupID}`)
    handleTaskList('createGroupChat')
    return data.conversation
  }

  const deleteConversation = async (conversationID: string) => {
    handleTaskList('deleteChat')
    return chat?.deleteConversation(conversationID)
  }

  const filterConversation = (conversationList: Array<any>) => {
    return conversationList.filter(item => {
      return item.type !== TencentCloudChat.TYPES.CONV_SYSTEM && item.conversationID !== 'GROUP@TUILiveKitDemo001'
    })
  }
  const showChat = () => {
    setModuleValue('chats')
  }

  // const showContacts = () => {
  //   setModuleValue('contacts')
  // }

  return (
    <div className="im-overlay" onClick={props?.onCancel}>
      <div className="im-box" onClick={e => e.stopPropagation()}>
        <div className={`sample-chat ${className}`}>
          <TUIKit chat={chat} language={'zh'} activeConversation={conversation}>
            <div className="sample-chat-left-container">
              <TUIProfile className="sample-chat-profile" />
              {/* <div className="sample-chat-tab">
                <div className="sample-chat-tab-container" onClick={showChat}>
                  <img src={moduleValue === 'chats' ? chatsSelected : chats} alt="" />
                  <p className={`sample-chat-tab-text ${moduleValue === 'chats' ? 'sample-chat-tab-active' : ''}`}>
                    消息
                  </p>
                </div>
                <div className="sample-chat-tab-container" onClick={showContacts}>
                  <img src={moduleValue === 'contacts' ? contactsSelected : contacts} alt="" />
                  <p className={`sample-chat-tab-text ${moduleValue === 'contacts' ? 'sample-chat-tab-active' : ''}`}>
                    通讯录
                  </p>
                </div>
              </div> */}
              {moduleValue === 'chats' && (
                <TUIConversation createConversation={handleCreateConversation} deleteConversation={deleteConversation}>
                  <TUIConversationList filterConversation={filterConversation} />
                </TUIConversation>
              )}
              {moduleValue === 'contacts' && <TUIContact />}
            </div>

            {moduleValue === 'chats' && (
              <>
                <TUIChat
                  EmptyPlaceholder={<TUIChatEmpty />}
                  sendMessage={handleSendMessage}
                  revokeMessage={handleRevokeMessage}
                >
                  <TUIChatHeader />
                  <TUIMessageList />
                  <TUIMessageInput />
                </TUIChat>
                <TUIManage></TUIManage>
              </>
            )}
            {moduleValue === 'contacts' && (
              <TUIContact>
                <TUIContactInfo showChat={showChat}></TUIContactInfo>
              </TUIContact>
            )}
          </TUIKit>
        </div>
      </div>
    </div>
  )
}
