import React from 'react'

export default function TUIChatEmpty(): React.ReactElement {
  return (
    <div className="tui-chat-default flex flex-col items-center justify-center">
      <img src={require('@/assets/images/imEmpty.png')} className="w-[290px] h-[240px] " alt="imEmpty" />
      <p className="text-base mt-2"> 聊天记录仅保存7天，请务必及时保存重要资料~</p>
    </div>
  )
}
