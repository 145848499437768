import axios, { AxiosInstance } from 'axios'

const api: AxiosInstance = axios.create({
  timeout: 5000
})

api.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    const Authorization = localStorage.getItem('Authorization')
    Authorization && (config.headers['Authorization'] = `Bearer ${Authorization}`)
    return config
  },
  (error: any) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    if (response.data.code === 401) {
      localStorage.removeItem('Authorization')
      window.location.href = '/login'
    } else if (response.data.code === 500) {
      // window.location.href = '/500'
    }
    return response.data
  },
  (error: any) => {
    // 对响应错误做些什么
    return Promise.reject(error)
  }
)

export default api
