import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
    >
      <g>
        <g>
          <path
            d="M10.1251220703125,5.625L10.1251220703125,3.375L2.2501220703125,3.375L2.2501220703125,14.625L10.1251220703125,14.625L10.1251220703125,12.375L11.2501220703125,12.375L11.2501220703125,15.1875C11.2501220703125,15.4982,10.9982820703125,15.75,10.6876220703125,15.75L1.6876220703125,15.75C1.3769620703125,15.75,1.1251220703125,15.4982,1.1251220703125,15.1875L1.1251220703125,2.8125C1.1251220703125,2.50184,1.3769620703125,2.25,1.6876220703125,2.25L10.6876220703125,2.25C10.9982820703125,2.25,11.2501220703125,2.50184,11.2501220703125,2.8125L11.2501220703125,5.625L10.1251220703125,5.625ZM15.1622220703125,8.43751L12.6357220703125,5.911L13.4311220703125,5.1155100000000004L16.9179220703125,8.602260000000001C17.1376220703125,8.82193,17.1376220703125,9.178090000000001,16.9179220703125,9.39776L13.4311220703125,12.8845L12.6357220703125,12.08902L15.1622220703125,9.56251L6.7500520703125,9.56249L6.7500520703125,8.43749L15.1622220703125,8.43751Z"
            fill-rule="evenodd"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
