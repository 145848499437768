const taskList = [
  {
    "key": "send",
    "label": "Send a message",
    "value": false
  },
  {
    "key": "recall",
    "label": "Recall a message",
    "value": false
  },
  {
    "key": "startChat",
    "label": "Start a Chat",
    "value": false
  },
  {
    "key": "deleteChat",
    "label": "Delete a chat",
    "value": false
  },
  {
    "key": "createGroupChat",
    "label": "Create a group chat",
    "value": false
  },
];

const taskLvieList = [
  {
    "key": "send",
    "label": "Send a message",
    "value": false
  },
  {
    "key": "follow",
    "label": "Follow",
    "value": false
  },
  {
    "key": "subscribe",
    "label": "Subscribe",
    "value": false
  },
  {
    "key": "like",
    "label": "Give a like",
    "value": false
  },
  {
    "key": "reward",
    "label": "Give a reward",
    "value": false
  },
  {
    "key": "participants",
    "label": "See the participants",
    "value": false
  },
];

const demoList = [
  {
    "label": "Web",
    "url": "https://intl.cloud.tencent.com/document/product/1047/45912"
  },
  {
    "label": "Android",
    "url": "https://intl.cloud.tencent.com/document/product/1047/45914"
  },
  {
    "label": "iOS",
    "url": "https://intl.cloud.tencent.com/document/product/1047/45913"
  },
  {
    "label": "Unity",
    "url": "https://intl.cloud.tencent.com/document/product/1047/41656"
  },
  {
    "label": "Unreal Engine",
    "url": "https://intl.cloud.tencent.com/document/product/1047/45908"
  },
  {
    "label": "Flutter",
    "url": "https://intl.cloud.tencent.com/document/product/1047/45907"
  },
  {
    "label": "Electron",
    "url": "https://intl.cloud.tencent.com/document/product/1047/45910"
  }
];
const advList = [
  {
    "label": "Free Trial",
    "btnText": "Get Started",
    "url": "https://www.tencentcloud.com/account/login?s_url=https%3A%2F%2Fconsole.tencentcloud.com%2Fim"
  },
  {
    "label": "Developer Group",
    "btnText": "Join",
    "url": "https://www.tencentcloud.com/document/product/1047/41676?lang=en&pg="
  }
]

export {
  taskList,
  demoList,
  advList,
  taskLvieList,
}
