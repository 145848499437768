import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="100%" height="100%" viewBox="0 0 18 18">
      <g>
        <g>
          <path
            d="M16.875,4.5L16.875,13.5C16.875,14.1213,16.371299999999998,14.625,15.75,14.625L2.25,14.625C1.6286800000000001,14.625,1.125,14.1213,1.125,13.5L1.125,4.5C1.125,3.87868,1.6286800000000001,3.375,2.25,3.375L15.75,3.375C16.371299999999998,3.375,16.875,3.87868,16.875,4.5ZM15.75,6.1875L15.75,4.5L2.25,4.5L2.25,6.1875L15.75,6.1875ZM2.25,7.3125L2.25,13.5L15.75,13.5L15.75,7.3125L2.25,7.3125ZM7.3125,12.375L3.9375,12.375L3.9375,11.25L7.3125,11.25L7.3125,12.375Z"
            fill-rule="evenodd"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
