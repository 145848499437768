import { taskList as taskListDefault } from '@/config/TaskBarJson'
import { BOUNDARY_WHDTH } from '@/config/constants'
import { useAuthContext } from '@/hook/useAuthContext'
import Tim from '@/page/Tim'
import { getImTencentSign } from '@/service/UCService'
import TencentCloudChat, { ChatSDK } from '@tencentcloud/chat'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import TIMUploadPlugin from 'tim-upload-plugin'
import Header from '../Header'
import ImIcon from '../ImIcon'
import ImIcon2 from '../ImIcon2'

const BaseLayout: React.FC = () => {
  const location = useLocation()
  const [showFooter, setShowFooter] = React.useState(false)
  const { width } = useWindowSize()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [userId, setUserId] = useState('')
  const { userInfo } = useAuthContext()
  const [chat, setChat] = useState<ChatSDK>()
  const [taskList, setTaskList] = useState(taskListDefault)
  const [messageNum, setMessageNum] = useState(0)

  const init = async (SDKAppID: number, userID: string, userSig: string): Promise<ChatSDK> => {
    return new Promise((resolve, reject) => {
      const chat = TencentCloudChat.create({ SDKAppID })
      chat?.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
      const onReady = () => {
        resolve(chat)
      }
      chat.on(TencentCloudChat.EVENT.SDK_READY, onReady)
      chat.login({
        userID,
        userSig
      })
    })
  }
  const handleTaskList = (key: string) => {
    const newTaskList = taskList.map(item => {
      if (item.key === key) {
        item.value = true
      }
      return item
    })
    setTaskList(newTaskList)
  }

  useEffect(() => {
    ;(async () => {
      if (userInfo) {
        const res = await getImTencentSign()
        const { sign, appId } = res.data
        const chat = await init(appId, userInfo?.userId || '', sign)
        chat.on(TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, onTotalUnreadMessageCountUpdated)
        setChat(chat)
      }
    })()
  }, [])

  // useEffect(() => {
  //   ;(async () => {
  //     if (chat) {
  //       const res = await chat?.getTotalUnreadMessageCount()
  //       console.log('resres', res)
  //     }
  //   })()
  // }, [chat])

  let onTotalUnreadMessageCountUpdated = function (event: any) {
    console.log('resres', event.data) // 当前单聊和群聊会话的未读总数
    setMessageNum(event.data)
  }

  useEffect(() => {
    if (width < BOUNDARY_WHDTH) {
      navigate('/m/home')
    }
  }, [width])

  // 获取当前页面的路径
  const currentPath = location.pathname

  // 定义首页的路径
  const homePath = '/'

  useEffect(() => {
    if (currentPath === homePath) {
      console.log('当前页面是首页')
      setShowFooter(true)
    } else {
      console.log('当前页面不是首页')
      setShowFooter(false)
    }
  }, [currentPath])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className="flex flex-col min-h-screen relative">
      <Header />
      <div className="flex-grow bg-[#f7f7f7]">
        <Outlet context={[isOpen, setIsOpen, userId, setUserId]} />
      </div>
      {userInfo && (
        // <FloatButton icon={<img src={require('@/assets/images/im.png')} alt="" />} onClick={() => setIsOpen(!isOpen)} />
        <div
          className=" cursor-pointer fixed right-[77px] bottom-[50px] w-[85px] h-[85px] "
          onClick={() => setIsOpen(!isOpen)}
        >
          {messageNum > 0 ? <ImIcon2 /> : <ImIcon />}
        </div>
      )}
      {/* {showFooter && <Footer />} */}
      {isOpen && <Tim chat={chat} handleTaskList={handleTaskList} onCancel={() => setIsOpen(false)} userId={userId} />}
    </div>
  )
}

export default BaseLayout
