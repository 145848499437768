import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="100%" height="100%" viewBox="0 0 18 18">
      <g>
        <g>
          <path
            d="M11.25,3.375C11.25,3.375,6.75,3.375,6.75,3.375C6.75,3.375,6.75,1.6875,6.75,1.6875C6.75,1.6875,5.625,1.6875,5.625,1.6875C5.625,1.6875,5.625,3.375,5.625,3.375C5.625,3.375,3.375,3.375,3.375,3.375C2.75368,3.375,2.25,3.87868,2.25,4.5C2.25,4.5,2.25,14.625,2.25,14.625C2.25,15.2463,2.753679,15.75,3.375,15.75C3.375,15.75,14.625,15.75,14.625,15.75C15.2463,15.75,15.75,15.2463,15.75,14.625C15.75,14.625,15.75,4.5,15.75,4.5C15.75,3.87868,15.2463,3.375,14.625,3.375C14.625,3.375,12.375,3.375,12.375,3.375C12.375,3.375,12.375,1.6875,12.375,1.6875C12.375,1.6875,11.25,1.6875,11.25,1.6875C11.25,1.6875,11.25,3.375,11.25,3.375ZM5.625,5.625C5.625,5.625,6.75,5.625,6.75,5.625C6.75,5.625,6.75,4.5,6.75,4.5C6.75,4.5,11.25,4.5,11.25,4.5C11.25,4.5,11.25,5.625,11.25,5.625C11.25,5.625,12.375,5.625,12.375,5.625C12.375,5.625,12.375,4.5,12.375,4.5C12.375,4.5,14.625,4.5,14.625,4.5C14.625,4.5,14.625,6.75,14.625,6.75C14.625,6.75,3.375,6.75,3.375,6.75C3.375,6.75,3.375,4.5,3.375,4.5C3.375,4.5,5.625,4.5,5.625,4.5C5.625,4.5,5.625,5.625,5.625,5.625ZM3.375,7.875C3.375,7.875,14.625,7.875,14.625,7.875C14.625,7.875,14.625,14.625,14.625,14.625C14.625,14.625,3.375,14.625,3.375,14.625C3.375,14.625,3.375,7.875,3.375,7.875Z"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
