import { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="100%" height="100%" viewBox="0 0 20 20">
      <defs>
        <clipPath id="master_svg0_6733_61567">
          <rect x="0" y="0" width="20" height="20" rx="0" />
        </clipPath>
      </defs>
      <g clip-path="url(#master_svg0_6733_61567)">
        <g>
          <path
            d="M17.312,2L2.6879999999999997,2C1.756,2,1,2.768,1,3.715L1,16.285C1,17.232,1.756,18,2.6879999999999997,18L17.312,18C18.244,18,19,17.232,19,16.285L19,3.715C19,2.768,18.244,2,17.312,2ZM2.6879999999999997,3.143L17.313,3.143C17.624,3.143,17.876,3.399,17.876,3.715L17.876,10.445C16.97,9.689,15.398,8.671,13.513,8.671C11.775,8.671,10.061,10.039,8.404,11.363C7.134,12.377,5.821,13.425,4.9,13.425C3.608,13.425,2.502,12.46,2.127,12.092L2.127,3.714C2.12311,3.40095,2.37493,3.14464,2.6879999999999997,3.143ZM17.312,16.857L2.6879999999999997,16.857C2.37446,16.854799999999997,2.12223,16.5985,2.125,16.285L2.125,13.572C2.799,14.057,3.775,14.567,4.898,14.567C6.209,14.567,7.612,13.446,9.098,12.26C10.605,11.057,12.163,9.812999999999999,13.512,9.812999999999999C15.93,9.812999999999999,17.847,11.975,17.866,11.997L17.875,11.989L17.875,16.285C17.8778,16.5985,17.6255,16.854799999999997,17.312,16.857ZM6.619,10.006C7.863,10.006,8.872,8.981,8.872,7.717C8.872,6.453,7.864,5.429,6.619,5.429C5.375,5.429,4.366,6.454,4.366,7.718C4.366,8.982,5.375,10.006,6.619,10.006ZM6.628,6.571C7.248,6.571,7.75,7.081,7.75,7.711C7.75,8.34,7.248,8.85,6.628,8.85C6.008,8.85,5.506,8.34,5.506,7.711C5.506,7.082,6.008,6.571,6.628,6.571Z"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
