import React, { FC, useEffect, useState } from 'react'

import { msgInfoUserCount } from '@/service/MsgService'
import { AuthContextType, RoleKeyType, UserInfo } from '@/type/uc'
import { getUserInfo, isLogin } from '@/util/account'
import logger from '@/util/logger'

export const AuthContext = React.createContext<AuthContextType>(null!)

// 获取用户信息
const Auth: FC<PropsType> = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [noticeCount, setNoticeCount] = useState(0)

  const checkLogin = async () => {
    try {
      const isLoginRes = await isLogin()
      if (isLoginRes) {
        const userInfo = await getUserInfo()
        if (localStorage.getItem(userInfo.userId) !== null) {
          const role = localStorage.getItem(userInfo.userId) as RoleKeyType
          userInfo.roleKey = role
        }
        setUserInfo(userInfo)
        getNoticeCount()
      }
    } catch (error) {
      logger.error('get user info error', error)
    }

    setLoaded(true)
  }

  useEffect(() => {
    checkLogin()
  }, [])

  const getNewUserInfo = async () => {
    try {
      const userInfo = await getUserInfo()
      if (localStorage.getItem(userInfo.userId) !== null) {
        const role = localStorage.getItem(userInfo.userId) as RoleKeyType
        userInfo.roleKey = role
      }
      setUserInfo(userInfo)
    } catch (error) {
      logger.error('get user info error', error)
    }
  }

  const getNoticeCount = async () => {
    try {
      const res = await msgInfoUserCount()
      console.log('res.data', res.data)
      setNoticeCount(res.data)
    } catch (error) {}
  }

  return loaded ? (
    <AuthContext.Provider value={{ userInfo, setUserInfo, getNewUserInfo, noticeCount, getNoticeCount }}>
      {children}
    </AuthContext.Provider>
  ) : null
}

// 页面鉴权
const RequireAuth: FC<PropsType> = ({ children }) => {
  const auth = React.useContext(AuthContext)
  if (!auth.userInfo) {
    // TODO: 跳转到登录
    window.location.href = '/login'
    return <div>require auth</div>
  }

  return <>{children}</>
}

interface PropsType {
  children: React.ReactNode
}

export { Auth, RequireAuth }
