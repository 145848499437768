import React, { useState } from 'react'
import './index.css'
import Search from '../Search'
import { useNavigate } from 'react-router-dom'
import Logo from '@/component/Logo'

const Header: React.FC = () => {
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)

  const onClick = () => {
    setVisible(!visible)
  }

  const goUrl = (url: string) => {
    setVisible(false)
    navigate(url)
  }

  return (
    <>
      <header className="bg-black fixed top-0 left-0 w-full z-50 header h-[240px] flex justify-end items-center">
        <div className="w-[522px] h-[120px] cursor-pointer ml-[84px] mr-[60px]" onClick={() => navigate('/m/home')}>
          <Logo />
        </div>
        <Search />
        <img
          src={require('@/assets/images/mobile/menu.png')}
          className="w-[72px] h-[72px] ml-auto mr-[84px] cursor-pointer"
          alt=""
          onClick={onClick}
        />
      </header>
      <div
        className={`${
          visible ? 'fixed' : 'hidden'
        } top-[240px] left-0 w-full z-50 h-[1092px] flex flex-col justify-between bg-[#000000]`}
      >
        <div
          className="ml-[84px] w-full h-[228px] text-[84px] leading-[228px] text-[#ffffff] cursor-pointer"
          onClick={() => goUrl('/m/teacher')}
        >
          教师名录
        </div>
        <div
          className="ml-[84px] w-full h-[228px] text-[84px] leading-[228px] text-[#ffffff] cursor-pointer"
          onClick={() => goUrl('/m/drawing')}
        >
          画力磁场
        </div>
        <div
          className="ml-[84px] w-full h-[228px] text-[84px] leading-[228px] text-[#ffffff] cursor-pointer"
          onClick={() => goUrl('/m/tip')}
        >
          注册
        </div>
        <div
          className="ml-[84px] w-full h-[228px] text-[84px] leading-[228px] text-[#ffffff] cursor-pointer"
          onClick={() => goUrl('/m/tip')}
        >
          登录
        </div>
      </div>
    </>
  )
}

export default Header
