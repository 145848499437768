import React, { FC, LazyExoticComponent } from 'react'

const LazyImport: FC<PropsType> = ({ lazyChildren: LazyChildren }) => {
  return (
    <React.Suspense fallback={<></>}>
      <LazyChildren />
    </React.Suspense>
  )
}

interface PropsType {
  lazyChildren: LazyExoticComponent<React.FC<{}>>
}

export default LazyImport
