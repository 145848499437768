import api from '@/config/api'
import { AVATAR, getApiBaseHost, getUploadBaseHost } from '@/config/constants'
import { PageParams, Result, Result2, Result3, ResultToken } from '@/type/common'
import { LoginUserInfo, ResultCheck, ResultFollows, ResultUserInfo, ReviewType, UserInfo } from '@/type/uc'

export const baseHost = getApiBaseHost(process.env.SDP_ENV)
export const uploadBaseHost = getUploadBaseHost(process.env.SDP_ENV)

export async function captchaSms(phonenumber?: number, type?: number) {
  try {
    let url = `${baseHost}/captchaSms`
    if (phonenumber) url = `${baseHost}/captchaSms?phonenumber=${phonenumber}`
    if (type) url = `${baseHost}/captchaSms?type=${type}`

    const res: Result = await api.get(url)
    return res
  } catch (error) {
    console.log(error)
  }
}
export async function captchaSmsType(phonenumber: number, type: number) {
  try {
    let url = `${baseHost}/captchaSms?`
    if (phonenumber) url = url + `&phonenumber=${phonenumber}`
    if (type) url = url + `&type=${type}`

    const res: Result = await api.get(url)
    return res
  } catch (error) {
    console.log(error)
  }
}

// /check/captchaSms
export async function checkCaptchaSms(verifyCode: string, phonenumber?: number) {
  try {
    let url = `${baseHost}/check/captchaSms?verifyCode=${verifyCode}`
    if (phonenumber) {
      url = `${baseHost}/check/captchaSms?verifyCode=${verifyCode}&phonenumber=${phonenumber}`
    }

    const res: Result = await api.get(url)
    return res
  } catch (error) {
    console.log(error)
  }
}

export async function register(body: LoginUserInfo): Promise<ResultToken> {
  try {
    const res: ResultToken = await api.post(`${baseHost}/register`, {
      ...body
    })
    return res
  } catch (error) {
    throw error
  }
}

export async function login(body: LoginUserInfo): Promise<ResultToken> {
  try {
    const res: ResultToken = await api.post(`${baseHost}/login`, {
      ...body
    })
    return res
  } catch (error) {
    throw error
  }
}

export async function logout() {
  try {
    const res: ResultToken = await api.post(`${baseHost}/logout`, {})
    return res
  } catch (error) {
    throw error
  }
}

export async function smsLogin(body: LoginUserInfo): Promise<ResultToken> {
  try {
    const res: ResultToken = await api.post(`${baseHost}/smsLogin`, {
      ...body
    })
    return res
  } catch (error) {
    throw error
  }
}

export async function getInfo(): Promise<UserInfo> {
  try {
    const res: ResultUserInfo = await api.get(`${baseHost}/getInfo`)

    const user: UserInfo = {
      roleKey: res.data.roles[0],
      userId: res.data.user.userId,
      nickName: res.data.user.nickName,
      avatar: res.data.user.avatar || AVATAR,
      certifyStatus: res.data.user.certifyStatus,
      isRealNameVerified: res.data.user.isRealNameVerified,
      description: res.data.user.description,
      phonenumber: res.data.user.phonenumber,
      preferenceTag: res.data.user.preferenceTag,
      rejectTag: res.data.user.rejectTag,
      studentCredibility: res.data.user.studentCredibility,
      teacherCredibility: res.data.user.teacherCredibility,
      score: res.data.user.score,
      scoreCount: res.data.user.scoreCount,
      excellentCertify: res.data.user.excellentCertify,
      groupInstruction: res.data.user.groupInstruction
    }
    return user
  } catch (error) {
    throw error
  }
}

// 修改用户 /system/user/profile
export async function updateUser(body: LoginUserInfo) {
  try {
    const res: Result = await api.put(`${baseHost}/system/user/profile`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
  }
}
// /system/user/profile/updatePhonenumber
export async function updatePhonenumber(body: LoginUserInfo): Promise<Result> {
  try {
    const res: Result = await api.put(`${baseHost}/system/user/profile/updatePhonenumber`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

export function customRequest({ file, onSuccess }: any) {
  const xhr = new XMLHttpRequest()
  xhr.open('POST', `${uploadBaseHost}/portal/file/upload`)
  const Authorization = localStorage.getItem('Authorization')
  Authorization && xhr.setRequestHeader('Authorization', `Bearer ${Authorization}`) // 添加自定义参数头
  const formData = new FormData()
  formData.append('file', file)
  xhr.send(formData)
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      onSuccess(JSON.parse(xhr.responseText), xhr)
    }
  }
}

// 下载文件 /portal/file/download
export async function downloadFile(url: string) {
  try {
    const res: Result = await api.get(`${baseHost}/portal/file/download?url=${url}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 修改密码 /system/user/profile/updatePwd
export async function updatePwd(body: LoginUserInfo): Promise<Result> {
  try {
    const res: Result = await api.put(`${baseHost}/system/user/profile/updatePwd`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 个人信息-其它用户 /system/user/profile/{userId}
export async function getUserInfoByUserId(userId: string): Promise<ResultUserInfo> {
  try {
    const res: ResultUserInfo = await api.get(`${baseHost}/system/user/profile/${userId}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//新增用户关注 /follows
export async function userFollows(teacherId: string): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/follows`, {
      teacherId
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//校验当前登录用户是否关注过该用户 /follows/check/{followUserId}
export async function userFollowsCheck(followUserId: string): Promise<Result3> {
  try {
    const res: Result3 = await api.get(`${baseHost}/follows/check/${followUserId}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//删除用户关注 /follows/{ids}
export async function userFollowsDelete(ids: string): Promise<Result> {
  try {
    const res: Result = await api.delete(`${baseHost}/follows/${ids}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//查询用户关注列表-我得 /follows/mine
export async function userFollowsMine(params: PageParams): Promise<ResultFollows> {
  try {
    const { pageNum, pageSize } = params
    let url = `${baseHost}/follows/mine?`
    if (pageNum) url += `&pageNum=${pageNum}`
    if (pageSize) url += `&pageSize=${pageSize}`
    const res: ResultFollows = await api.get(url)
    return res
  } catch (error) {
    throw error
  }
}

// 保存用户标签 /tag/user
export async function saveUserTag(body: { preferenceTag: string; rejectTag: string }): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/tag/user`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 获取用户签名 /live/tencent/sign
export async function getTencentSign(): Promise<any> {
  try {
    const res: any = await api.get(`${baseHost}/live/tencent/sign?version=new`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//新增教师审核 /other/review
export async function otherReview(body: ReviewType): Promise<Result> {
  try {
    const res: Result = await api.post(`${baseHost}/other/review`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 判断审核过没 /other/review/check
export async function otherReviewCheck(): Promise<ResultCheck> {
  try {
    const res: ResultCheck = await api.get(`${baseHost}/other/review/check`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

//根据用户编号获取详细信息 /system/user/{userId}
export async function getSystemUser(userId: string): Promise<ResultUserInfo> {
  try {
    const res: ResultUserInfo = await api.get(`${baseHost}/system/user/${userId}`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 实名认证 /system/user/realname-verification
export async function realnameVerification(body: { name: string; idCard: string }): Promise<Result2> {
  try {
    const res: Result2 = await api.post(`${baseHost}/system/user/realname-verification`, {
      ...body
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

// 获取腾讯签名 /im/tencent/sign
export async function getImTencentSign(): Promise<any> {
  try {
    const res: any = await api.get(`${baseHost}/im/tencent/sign`)
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}
