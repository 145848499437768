import { RequireAuth } from '@/component/Auth'
import BaseLayout from '@/component/Layout/BaseLayout'
import MobileLayout from '@/component/Layout/MobileLayout'
import LazyImport from '@/component/LazyImport'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const routes: RouteObject[] = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: <LazyImport lazyChildren={lazy(() => import('../page/Home'))} />
      },

      {
        path: 'user',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/User'))} />
          </RequireAuth>
        )
      },
      {
        path: 'teacher',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Teacher'))} />
      },
      {
        path: 'teacher/guide',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Teacher/Guide'))} />
          </RequireAuth>
        )
      },
      {
        path: 'student/guide',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Student/Guide'))} />
          </RequireAuth>
        )
      },
      {
        path: 'teacher/detail',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Teacher/Detail'))} />
          </RequireAuth>
        )
      },
      {
        path: 'teacher/invitation',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Teacher/Invitation'))} />
          </RequireAuth>
        )
      },
      {
        path: 'teacher/auth',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Teacher/Auth'))} />
          </RequireAuth>
        )
      },
      {
        path: 'works',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Works'))} />
          </RequireAuth>
        )
      },
      {
        path: 'arrangement',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Arrangement'))} />
          </RequireAuth>
        )
      },
      {
        path: 'course',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Course/index'))} />
          </RequireAuth>
        )
      },
      {
        path: 'rush',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Rush'))} />
          </RequireAuth>
        )
      },
      {
        path: 'student/rush',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Student/Rush'))} />
          </RequireAuth>
        )
      },
      {
        path: 'study',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Study'))} />
          </RequireAuth>
        )
      },
      {
        path: 'setting',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Setting'))} />
          </RequireAuth>
        )
      },
      {
        path: 'groupInstruction',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/GroupInstruction'))} />
          </RequireAuth>
        )
      },
      {
        path: 'drawing',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Drawing'))} />
      },
      {
        path: 'order',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Order'))} />
          </RequireAuth>
        )
      },
      {
        path: 'teacher/order',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Teacher/Order'))} />
          </RequireAuth>
        )
      },
      {
        path: 'student/order',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Student/Order'))} />
          </RequireAuth>
        )
      },
      {
        path: 'result',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Result'))} />
          </RequireAuth>
        )
      },
      {
        path: 'specification',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Specification'))} />
      },
      {
        path: 'service',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Service'))} />
      },
      {
        path: 'privacy',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Privacy'))} />
      },
      {
        path: 'protocol',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Protocol'))} />
      },
      {
        path: 'message',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Message'))} />
          </RequireAuth>
        )
      },
      {
        path: 'activity',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Activity'))} />
      },
      {
        path: 'activity/detail',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Activity/Detail'))} />
          </RequireAuth>
        )
      },
      {
        path: 'group',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Group'))} />
      },
      {
        path: 'group/detail',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Group/Detail'))} />
          </RequireAuth>
        )
      },
      {
        path: 'vip',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Vip'))} />
          </RequireAuth>
        )
      },
      {
        path: 'vip/order',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Vip/Order'))} />
          </RequireAuth>
        )
      },
      {
        path: 'vip/result',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Vip/Result'))} />
          </RequireAuth>
        )
      },
      {
        path: 'wallet',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Wallet'))} />
          </RequireAuth>
        )
      },
      {
        path: 'recharge',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Recharge'))} />
          </RequireAuth>
        )
      },
      {
        path: 'recharge/result',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/RechargeResult'))} />
          </RequireAuth>
        )
      },
      {
        path: 'withdrawal',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Withdrawal'))} />
          </RequireAuth>
        )
      },
      {
        path: 'withdrawal/result',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/WithdrawalResult'))} />
          </RequireAuth>
        )
      },
      {
        path: 'collect',
        element: (
          <RequireAuth>
            <LazyImport lazyChildren={lazy(() => import('../page/Collect'))} />
          </RequireAuth>
        )
      },
      {
        path: '500',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Error/500'))}></LazyImport>
      },
      {
        path: '404',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Error/404'))}></LazyImport>
      },
      {
        path: '*',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Error/404'))}></LazyImport>
      },
      {
        path: 'about',
        element: <LazyImport lazyChildren={lazy(() => import('../page/About'))} />
      }
    ]
  },
  {
    path: 'login',
    element: <LazyImport lazyChildren={lazy(() => import('../page/Login'))} />
  },
  {
    path: 'register',
    element: <LazyImport lazyChildren={lazy(() => import('../page/Register'))} />
  },
  {
    path: 'live',
    element: (
      <RequireAuth>
        <LazyImport lazyChildren={lazy(() => import('../page/Live'))} />
      </RequireAuth>
    )
  },
  {
    path: 'liveGroup',
    element: (
      <RequireAuth>
        <LazyImport lazyChildren={lazy(() => import('../page/LiveGroup'))} />
      </RequireAuth>
    )
  },
  {
    path: 'm',
    element: <MobileLayout />,
    children: [
      {
        path: 'home',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Mobile/Home'))} />
      },
      {
        path: 'teacher',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Mobile/Teacher'))} />
      },
      {
        path: 'drawing',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Mobile/Drawing'))} />
      },
      {
        path: 'tip',
        element: <LazyImport lazyChildren={lazy(() => import('../page/Mobile/Tip'))} />
      }
    ]
  },
  {
    path: '*',
    element: <LazyImport lazyChildren={lazy(() => import('../page/Error/404'))}></LazyImport>
  }
]

export default routes
