import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      version="1.1"
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
    >
      <g>
        <g>
          <path
            d="M17.0018,4.054930000000001C17.0018,4.054930000000001,17.0018,2,17.0018,2C17.0018,2,15.0018,2,15.0018,2C15.0018,2,15.0018,4.054930000000001,15.0018,4.054930000000001C10.50187,4.55237,7.00182,8.36745,7.00182,13C7.00182,13,7.00182,22,7.00182,22C7.00182,22,5.201816,24.4,5.201816,24.4C4.707388,25.0592,5.17777,26,6.00182,26C6.00182,26,11.10183,26,11.10183,26C11.565100000000001,28.2822,13.58286,30,16.0018,30C18.4208,30,20.438499999999998,28.2822,20.9018,26C20.9018,26,26.0018,26,26.0018,26C26.8259,26,27.2962,25.0592,26.8018,24.4C26.8018,24.4,25.0018,22,25.0018,22C25.0018,22,25.0018,13,25.0018,13C25.0018,8.36745,21.5018,4.55237,17.0018,4.054930000000001Z"
            fill="#FFFFFF"
            fill-opacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
