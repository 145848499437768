import { useAuthContext } from '@/hook/useAuthContext'
import { logout } from '@/service/UCService'
import { UserInfo } from '@/type/uc'
import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Balance from '../Balance'
import GroupInstructionModal from '../GroupInstructionModal'
import Logo from '../Logo'
import CalendarIcon from '../SVG/Menu/CalendarIcon'
import CreditcardIcon from '../SVG/Menu/CreditcardIcon'
import GroupIcon from '../SVG/Menu/GroupIcon'
import { default as HomeIcon, default as WalletIcon } from '../SVG/Menu/HomeIcon'
import LogoutIcon from '../SVG/Menu/LogoutIcon'
import RefreshIcon from '../SVG/Menu/RefreshIcon'
import RushIcon from '../SVG/Menu/RushIcon'
import SettingIcon from '../SVG/Menu/SettingIcon'
import StudyIcon from '../SVG/Menu/StudyIcon'
import MessageIcon from '../SVG/MessageIcon'
import WalletIcon2 from '../SVG/WalletIcon'
import Search from '../Search'
import './index.css'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const type = pathname.substring(pathname.lastIndexOf('/') + 1)
  const { userInfo, setUserInfo, noticeCount, getNoticeCount } = useAuthContext()
  const [items, setItems] = useState<MenuProps['items']>([])
  const [hover2, setHover2] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isGroupInstructionModalOpen, setIsGroupInstructionModalOpen] = useState(false)

  const handVisibleOk = () => {
    setVisible(true)
  }

  const handVisibleCancel = () => {
    setVisible(false)
  }

  const handleMouseEnter2 = () => {
    setHover2(true)
  }

  const handleMouseLeave2 = () => {
    setHover2(false)
  }

  const onClick = (path: string) => {
    navigate(path)
  }

  const goLogout = async (event: React.MouseEvent) => {
    event.preventDefault() // 阻止默认的跳转行为
    try {
      await logout()
      localStorage.removeItem('Authorization')
      window.location.href = '/'
    } catch (error) {}
  }

  useEffect(() => {
    if (userInfo) {
      if (userInfo.roleKey === 'teacher') {
        setItems([
          {
            label: (
              <Link to="/teacher/guide">
                <div className="item">
                  <div className="svg">
                    <HomeIcon />
                  </div>
                  <span className="title"> 我的主页</span>
                </div>
              </Link>
            ),
            key: 'home'
          },
          {
            label: <div className="border"></div>,
            key: 'border1'
          },
          {
            label: (
              <Link to="/course">
                <div className="item">
                  <div className="svg">
                    <StudyIcon />
                  </div>
                  <span className="title"> 课程安排</span>
                </div>
              </Link>
            ),
            key: 'course'
          },
          {
            label: (
              <Link to="/rush">
                <div className="item">
                  <div className="svg">
                    <RushIcon />
                  </div>
                  <span className="title"> 特邀改图</span>
                </div>
              </Link>
            ),
            key: 'rush'
          },
          {
            label: (
              <Link to="/arrangement">
                <div className="item">
                  <div className="svg">
                    <CalendarIcon />
                  </div>
                  <span className="title"> 档期设置</span>
                </div>
              </Link>
            ),
            key: 'arrangement'
          },
          {
            label: (
              <a onClick={() => goPage('/groupInstruction')}>
                <div className="item">
                  <div className="svg">
                    <GroupIcon />
                  </div>
                  <span className="title"> 团课设置</span>
                </div>
              </a>
            ),
            key: 'wallet'
          },
          {
            label: (
              <Link to="/wallet">
                <div className="item">
                  <div className="svg">
                    <WalletIcon />
                  </div>
                  <span className="title"> 我的钱包</span>
                </div>
              </Link>
            ),
            key: 'wallet'
          },
          {
            label: (
              <Link to="/setting">
                <div className="item">
                  <div className="svg">
                    <SettingIcon />
                  </div>
                  <span className="title"> 账户设置</span>
                </div>
              </Link>
            ),
            key: 'setting'
          },
          // {
          //   label: (
          //     // <Link to="/collect">
          //     <Link to="/teacher?follows=true">
          //       <div className="item">
          //         <div className="svg">
          //           <StarIcon />
          //         </div>
          //         <span className="title"> 我的关注</span>
          //       </div>
          //     </Link>
          //   ),
          //   key: 'collect'
          // },
          {
            label: (
              <Link to="/#" onClick={onchange}>
                <div className="item">
                  <div className="svg">
                    <RefreshIcon />
                  </div>
                  <span className="title"> 切换身份</span>
                </div>
              </Link>
            ),
            key: 'change'
          },
          {
            label: <div className="border"></div>,
            key: 'border2'
          },
          {
            label: (
              <Link to="/#" onClick={goLogout}>
                <div className="item">
                  <div className="svg">
                    <LogoutIcon />
                  </div>
                  <span className="title"> 退出登录</span>
                </div>
              </Link>
            ),
            key: 'logout'
          }
        ])
      } else if (userInfo.roleKey === 'student') {
        setItems([
          {
            label: (
              <Link to="/student/guide">
                <div className="item">
                  <div className="svg">
                    <HomeIcon />
                  </div>
                  <span className="title"> 我的主页</span>
                </div>
              </Link>
            ),
            key: 'home'
          },
          {
            label: <div className="border"></div>,
            key: 'border1'
          },
          {
            label: (
              <Link to="/study">
                <div className="item">
                  <div className="svg">
                    <StudyIcon />
                  </div>
                  <span className="title"> 课程安排</span>
                </div>
              </Link>
            ),
            key: 'study'
          },
          {
            label: (
              <Link to="/student/rush">
                <div className="item">
                  <div className="svg">
                    <RushIcon />
                  </div>
                  <span className="title"> 特邀改图</span>
                </div>
              </Link>
            ),
            key: 'rush'
          },
          {
            label: (
              <Link to="/wallet">
                <div className="item">
                  <div className="svg">
                    <WalletIcon />
                  </div>
                  <span className="title"> 我的钱包</span>
                </div>
              </Link>
            ),
            key: 'wallet'
          },
          {
            label: (
              <Link to="/student/order">
                <div className="item">
                  <div className="svg">
                    <CreditcardIcon />
                  </div>
                  <span className="title"> 支付订单</span>
                </div>
              </Link>
            ),
            key: 'order'
          },
          {
            label: (
              <Link to="/setting">
                <div className="item">
                  <div className="svg">
                    <SettingIcon />
                  </div>
                  <span className="title"> 账户设置</span>
                </div>
              </Link>
            ),
            key: 'setting'
          },
          // {
          //   label: (
          //     <Link to="/teacher?follows=true">
          //       <div className="item">
          //         <div className="svg">
          //           <StarIcon />
          //         </div>
          //         <span className="title"> 我的关注</span>
          //       </div>
          //     </Link>
          //   ),
          //   key: 'collect'
          // },
          {
            label: (
              <Link to="/#" onClick={onchange}>
                <div className="item">
                  <div className="svg">
                    <RefreshIcon />
                  </div>

                  <span className="title"> 切换身份</span>
                </div>
              </Link>
            ),
            disabled: userInfo?.certifyStatus !== 2 ? true : false,
            key: 'change'
          },
          {
            label: <div className="border"></div>,
            key: 'border2'
          },
          {
            label: (
              <Link to="/#" onClick={goLogout}>
                <div className="item">
                  <div className="svg">
                    <LogoutIcon />
                  </div>
                  <span className="title"> 退出登录</span>
                </div>
              </Link>
            ),
            key: 'logout'
          }
        ])
      }
    }
  }, [userInfo])

  const goPage = (path: string) => {
    if (path !== '/groupInstruction' || userInfo?.groupInstruction === 1) {
      navigate(path)
    } else {
      //显示框内容
      setIsGroupInstructionModalOpen(true)
    }
  }

  const onchange = (event: React.MouseEvent) => {
    event.preventDefault() // 阻止默认的跳转行为
    if (userInfo) {
      const newUserInfo: UserInfo = {
        ...userInfo,
        roleKey: userInfo.roleKey === 'teacher' ? 'student' : 'teacher'
      }
      localStorage.setItem(userInfo.userId, newUserInfo.roleKey)
      setUserInfo(newUserInfo)
      if (newUserInfo.roleKey === 'teacher') {
        navigate('/teacher/guide')
      } else {
        navigate('/student/guide')
      }
    }
  }

  const onClickLogo = () => {
    if (userInfo) {
      // if (userInfo.roleKey === 'teacher') {
      //   navigate('/teacher/guide')
      // } else {
      //   navigate('/student/guide')
      // }
      navigate('/')
    } else {
      navigate('/')
    }
  }

  return (
    <header className="header fixed left-0 top-0 z-50 w-full bg-black">
      {userInfo && <Balance visible={visible} onCancel={handVisibleCancel} />}

      <div className={`flex h-[84px] items-center pl-[130px] ${userInfo ? 'pr-[120px]' : 'pr-[120px]'}`}>
        <div className="h-[30px] w-[223px] cursor-pointer" onClick={onClickLogo}>
          <Logo />
        </div>
        <Link to="/teacher" className={`menu ${type === 'teacher' ? 'active' : ''}`}>
          教师名录
        </Link>
        <Link to="/drawing" className={`menu ${type === 'drawing' ? 'active' : ''}`}>
          磁力画廊
        </Link>
        {/* <Link to="/about" className={`menu ${type === 'about' ? 'active' : ''}`}>
          关于我们
        </Link> */}
        <Link to="/activity" className={`menu ${type === 'activity' ? 'active' : ''}`}>
          充磁计划
        </Link>
        <Link to="/group" className={`menu ${type === 'group' ? 'active' : ''}`}>
          磁极课堂
        </Link>
        <Link to="/vip" className={`menu ${type === 'vip' ? 'active' : ''}`}>
          会员中心
        </Link>
        {userInfo && (
          <Link
            to={userInfo.roleKey === 'teacher' ? '/teacher/guide' : '/student/guide'}
            className={`menu ${type === 'guide' ? 'active' : ''}`}
          >
            我的主页
          </Link>
        )}
        <div className="ml-auto flex items-center">
          <Search />
          {userInfo ? (
            <div className="ml-[30px] flex items-center">
              <div
                className="flex h-[84px] items-center"
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
              >
                <div className=" relative mr-[30px]">
                  {/* <img
                    className="w-[32px] h-[32px] rounded-full object-cover cursor-pointer "
                    src={require('@/assets/images/header/message.png')}
                    alt="wallet"
                    onClick={() => navigate('/message')}
                  /> */}
                  <div
                    className="h-[32px] w-[32px] cursor-pointer rounded-full object-cover "
                    onClick={() => navigate('/message')}
                  >
                    <MessageIcon />
                  </div>

                  {noticeCount > 0 && (
                    <div className=" absolute right-0 top-0 h-[14px] w-[14px] rounded-full bg-[#EA0000] text-center text-[10px] leading-[14px] text-[#ffffff]">
                      {noticeCount}
                    </div>
                  )}
                </div>

                <div
                  className="mr-[30px] h-[32px] w-[32px] cursor-pointer rounded-full object-cover"
                  onClick={handVisibleOk}
                >
                  <WalletIcon2 />
                </div>
                <Dropdown overlayClassName="header-drop" menu={{ items }} trigger={['click']}>
                  <img
                    className="h-[36px] w-[36px] cursor-pointer rounded-full object-cover"
                    src={userInfo.avatar}
                    alt="avatar"
                  />
                </Dropdown>
              </div>
            </div>
          ) : (
            <>
              <Button className="register ml-[30px]" onClick={() => onClick('register')}>
                注册
              </Button>
              <Button className="login" onClick={() => onClick('login')}>
                登录
              </Button>
            </>
          )}
        </div>
      </div>
      {isGroupInstructionModalOpen && (
        <GroupInstructionModal
          modalOpen={isGroupInstructionModalOpen}
          onCancel={() => setIsGroupInstructionModalOpen(false)}
        />
      )}
    </header>
  )
}

export default Header
