import { useAuthContext } from '@/hook/useAuthContext'
import { checkQualification, submitQualification } from '@/service/GroupInstructionService'
import { QualificationCheck } from '@/type/groupInstruction'
import { Button, Modal, message } from 'antd'
import { FC, useEffect, useState } from 'react'
import './index.css'

interface PropsType {
  modalOpen?: boolean
  onCancel: () => void
}
const GroupInstructionModal: FC<PropsType> = ({ onCancel, modalOpen }) => {
  const { userInfo, getNewUserInfo } = useAuthContext()
  const [checkItem, setCheckItem] = useState<QualificationCheck>()
  const [loading, setLoading] = useState(false)
  const check = async () => {
    const res = await checkQualification()
    if (res.code === 200) {
      setCheckItem(res.data)
    }
  }

  const onClick = async () => {
    setLoading(true)
    const res = await submitQualification()
    if (res.code === 200) {
      message.success('申请成功')
      getNewUserInfo()
      onCancel()
    } else {
      setLoading(false)
      message.error(res.msg)
    }
  }

  useEffect(() => {
    check()
  }, [])

  return (
    <Modal width={'25vw'} title="团课资格申请" open={modalOpen} onCancel={onCancel} footer={null}>
      <div className="groupInstruction-modal">
        {/* <div className="flex items-center justify-between ">
          <div className=" text-[16px] text-[#282828] flex items-center">
            团课资格申请
          </div>
          <img
            className="w-[24px] h-[24px] cursor-pointer"
            src={require('@/assets/images/close.png')}
            alt="close"
            onClick={onCancel}
          />
        </div> */}
        <div className="mb-[23px] mt-[23px] h-[1px] w-full bg-[#D8D8D8]"></div>
        <p className="text">很抱歉，您暂未获得开设团课的资格。获得开设团课的资格需要：</p>
        <p className="text mt-[12px]"> 1. 完成5节及以上一对一私教课程并结课；</p>
        <p className="text">2. 教师端信誉分大于等于4.5分；</p>
        <div className="mt-[16px] rounded-[10px] bg-[#EEEEEE] px-[12px] py-[10px]">
          <p className="text">
            * 当前您的账号教师端信誉分为<span className=" text-[red]">{checkItem?.creditScore}</span>分
          </p>
          <p className="text">
            * 已结课一对一私教课程<span className=" text-[red]">{checkItem?.courseCount}</span>节
          </p>
          <p className="text ">
            <span className="text-[red]">{checkItem?.isPass ? '* 符合资格申请' : '* 不符合资格申请'}</span>
          </p>
        </div>
        <div className="mt-[16px] text-center">
          {userInfo?.groupInstruction == 0 ? (
            <Button className="button disabled-button" disabled>
              审核中
            </Button>
          ) : checkItem?.isPass ? (
            <Button loading={loading} className="green-button button " onClick={onClick}>
              申请资格
            </Button>
          ) : (
            <Button className=" button disabled-button" disabled>
              申请资格
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default GroupInstructionModal
