import { Button } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { balanceCurrent } from '@/service/Withdraw'
import { BalanceType } from '@/type/withdraw'
import CustomModal from '../CustomModal'
import { useAuthContext } from '@/hook/useAuthContext'

interface PropsType {
  visible: boolean
  onCancel: () => void
}

const Balance: FC<PropsType> = ({ visible, onCancel }) => {
  const { userInfo } = useAuthContext()
  const ref: React.MutableRefObject<any> = useRef(null)
  const navigate = useNavigate()
  const [balance, setBalance] = useState<BalanceType>({
    balance: 0,
    gifts: 0
  })

  const getBalance = async () => {
    try {
      const res = await balanceCurrent()
      setBalance(res.data)
    } catch (error) {}
  }

  useEffect(() => {
    if (visible) {
      getBalance()
      ref.current.style.position = 'fixed'
      ref.current.style.display = 'initial'
    } else {
      ref.current.style.display = 'none'
    }
  }, [visible])

  const handleMouseLeave = () => {
    onCancel()
  }

  const [isVisiable, setIsVisiable] = useState(false)
  const goPage = (path: string) => {
    if (!userInfo?.isRealNameVerified) {
      setIsVisiable(true)
      return
    }
    navigate(path)
  }
  const goPage2 = (path: string) => {
    navigate(path)
  }

  return (
    <div
      className="balance hidden w-[320px] h-[164px] rounded-[20px] bg-white p-[16px] top-[104px] right-[20px]"
      ref={ref}
      onMouseLeave={() => handleMouseLeave()}
    >
      <p className="text-[24px] leading-[28px] text-[#000000]/90">我的账户</p>
      <div className="w-full h-[64px] bg-[#767676]/10 py-[8px] mt-[8px]">
        <p className="text-[14px] leading-[20px] text-[#3D3D3D]/60 ml-[8px] ">账户余额</p>
        <div className="flex items-center">
          <p className="text-[28px] leading-[36px] text-[#000000]/90 ml-[8px] mt-[8px]">￥{balance.balance}</p>
          <p className="text-[12px] leading-[14px] text-[#1A1A1A] ml-[8px] mt-[8px]">（体验金:￥{balance.gifts}）</p>
        </div>
      </div>
      <div className=" text-right mt-[16px]">
        <Button className="green-button button mr-[8px]" onClick={() => goPage2('/recharge')}>
          充值
        </Button>
        <Button className="black-button button" onClick={() => goPage('/withdrawal')}>
          提现
        </Button>
      </div>
      <CustomModal visible={isVisiable} onClose={() => setIsVisiable(false)} />
    </div>
  )
}

export default Balance
