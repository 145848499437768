import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '../SVG/SearchIcon'

const Search: React.FC = () => {
  const [value, setValue] = useState('')
  const navgate = useNavigate()

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  const goSearch = () => {
    navgate(`/teacher?name=${value}`)
  }

  return (
    <div className=" w-[310px] h-[36px] bg-[#FFFFFF] rounded-[10px] flex items-center px-[13px]">
      <div className="mr-[12px] w-[17px] h-[17px] flex">
        <SearchIcon />
      </div>
      <input
        type="text"
        className="w-full bg-[#FFFFFF] border-none appearance-none outline-none text-[12px] text-black/60"
        placeholder={'搜索你喜欢的老师'}
        value={value}
        onChange={onChange}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            goSearch()
          }
        }}
      />
    </div>
  )
}

export default Search
